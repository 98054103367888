import React from 'react';
import {
  List,
  Datagrid,
  TextField,
  EditButton,
  NumberField,
  ReferenceField,
} from 'react-admin';

const WorkshopItemList = props => (
  <List {...props} bulkActionButtons={false}>
    <Datagrid rowClick="edit">
      <TextField source="id" />
      <TextField source="title" />
      <NumberField source="subscribers" />
      <NumberField source="endorsments" />
    </Datagrid>
  </List>
);

export default WorkshopItemList;
