import React from 'react';
import {
  List,
  Datagrid,
  TextField,
  NumberField,
  ArrayField,
  ChipField,
} from 'react-admin';

const WorkshopCampaignList = props => (
  <List {...props} bulkActionButtons={false}>
    <Datagrid>
      <TextField source="id" />
      <TextField source="title" />
      <ArrayField source="items">
        <Datagrid>
          <ChipField source="title" />
        </Datagrid>
      </ArrayField>
      <NumberField source="subscribers" />
      <NumberField source="endorsments" />
    </Datagrid>
  </List>
);

export default WorkshopCampaignList;
