import React from 'react';
import {
  List,
  Datagrid,
  TextField,
  EditButton,
  NumberField,
} from 'react-admin';

const AchievementList = props => (
  <List {...props} bulkActionButtons={false}>
    <Datagrid rowClick="edit">
      <TextField source="id" />
      <TextField source="visibility" />
      <TextField source="name" />
      <NumberField label="Achieved by" source="achieversCount" />
      <TextField source="metadata" />
      <EditButton />
    </Datagrid>
  </List>
);

export default AchievementList;
