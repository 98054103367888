import {
  GET_LIST,
  GET_ONE,
  GET_MANY,
  GET_MANY_REFERENCE,
  CREATE,
  UPDATE,
  DELETE,
  DELETE_MANY,
  fetchUtils,
} from 'react-admin';
import { stringify } from 'query-string';
import pluralize from 'pluralize';

const API_URL = `${process.env.REACT_APP_API_URL}/admin`;

const singularize = word => pluralize(word, 1);

/**
 * Convert a `File` object returned by the upload input into a base 64 string.
 * That's not the most optimized way to store images in production, but it's
 * enough to illustrate the idea of data provider decoration.
 */
const convertFileToBase64 = file => new Promise((resolve, reject) => {
  const reader = new FileReader();
  reader.onload = () => resolve(reader.result);
  reader.onerror = reject;

  reader.readAsDataURL(file.rawFile);
});

const prepareFiles = async (data) => {
  const { payload, preview_payload: previewPayload } = data;
  const convertedPayload = await convertFileToBase64(payload);
  const convertedPreview = await convertFileToBase64(previewPayload);
  return {
    ...data,
    payload: convertedPayload,
    preview_payload: convertedPreview,
  };
};

/**
 * @param {String} type One of the constants appearing at the top of this file, e.g. 'UPDATE'
 * @param {String} resource Name of the resource to fetch, e.g. 'posts'
 * @param {Object} params The Data Provider request params, depending on the type
 * @returns {Object} { url, options } The HTTP request parameters
 */
const convertDataProviderRequestToHTTP = (type, resource, params) => {
  switch (type) {
    case GET_LIST: {
      const { page, perPage } = params.pagination;
      const { field, order } = params.sort;
      const query = {
        sort: JSON.stringify([field, order]),
        page: JSON.stringify(page),
        perPage: JSON.stringify(perPage),
        // range: JSON.stringify([(page - 1) * perPage, page * perPage - 1]),
        filter: JSON.stringify(params.filter),
      };
      return { url: `${API_URL}/read/all/${resource}?${stringify(query)}`, options: {} };
    }
    case GET_ONE: {
      let url = null;
      switch (resource) {
        default:
          url = `${API_URL}/${singularize(resource)}/${params.id}/read`;
          break;
      }
      return {
        url,
        options: {},
      };
    }
    case GET_MANY: {
      const query = {
        hashids: JSON.stringify(params.ids),
      };
      return { url: `${API_URL}/read/many/${resource}?${stringify(query)}`, options: {} };
    }
    case GET_MANY_REFERENCE: {
      const { page, perPage } = params.pagination;
      const { field, order } = params.sort;
      const query = {
        sort: JSON.stringify([field, order]),
        range: JSON.stringify([(page - 1) * perPage, (page * perPage) - 1]),
        filter: JSON.stringify({ ...params.filter, [params.target]: params.id }),
      };
      return { url: `${API_URL}/${resource}?${stringify(query)}` };
    }
    case UPDATE: {
      const body = params.data;
      switch (resource) {
        case 'leaderboards':
          body.world_id = body.world.id;
          break;
        default:
          break;
      }
      return {
        url: `${API_URL}/${singularize(resource)}/${params.id}/update`,
        options: { method: 'PUT', body: JSON.stringify(body) },
      };
    }
    case CREATE: {
      let url = null;
      switch (resource) {
        case 'weeklychallengeitems':
          return {
            url: `${API_URL}/${singularize(resource)}/create`,
            options: { method: 'POST', body: params.data, convertFiles: true },
          };
        default:
          url = `${API_URL}/${singularize(resource)}/create`;
          break;
      }
      return {
        url,
        options: { method: 'POST', body: JSON.stringify(params.data) },
      };
    }
    case DELETE: {
      let url = null;
      switch (resource) {
        default:
          url = `${API_URL}/${singularize(resource)}/${params.id}/delete`;
          break;
      }
      return {
        url,
        options: { method: 'DELETE' },
      };
    }
    case DELETE_MANY: {
      const query = {
        hashids: JSON.stringify(params.ids),
      };
      return { url: `${API_URL}/delete/many/${resource}?${stringify(query)}`, options: {} };
    }
    default:
      throw new Error(`Unsupported fetch action type ${type}`);
  }
};

/**
 * @param {Object} response HTTP response from fetch()
 * @param {String} type One of the constants appearing at the top of this file, e.g. 'UPDATE'
 * @param {String} resource Name of the resource to fetch, e.g. 'posts'
 * @param {Object} params The Data Provider request params, depending on the type
 * @returns {Object} Data Provider response
 */
const convertHTTPResponseToDataProvider = (response, type, resource, params) => {
  const { json } = response;
  switch (type) {
    case GET_LIST:
      return {
        data: json.data,
        total: json.meta.total,
      };
    case GET_MANY:
      return {
        data: json.data,
        total: json.meta.total,
      };
    case CREATE:
      return { data: { ...params.data, id: json.id } };
    case DELETE:
      return { data: { id: resource.id } };
    case DELETE_MANY:
      return { data: params.ids };
    default:
      return { data: json };
  }
};

/**
 * @param {string} type Request type, e.g GET_LIST
 * @param {string} resource Resource name, e.g. "posts"
 * @param {Object} payload Request parameters. Depends on the request type
 * @returns {Promise} the Promise for response
 */
export default async (type, resource, params) => {
  const { fetchJson } = fetchUtils;
  const { url, options } = convertDataProviderRequestToHTTP(type, resource, params);
  if (options.convertFiles) {
    const preparedBody = await prepareFiles(options.body);
    options.body = JSON.stringify(preparedBody);
  }
  if (!options.headers) {
    options.headers = new Headers({ Accept: 'application/json' });
  }
  const token = localStorage.getItem('accessToken');
  options.headers.set('Authorization', `Bearer ${token}`);
  return fetchJson(url, options)
    .then(response => convertHTTPResponseToDataProvider(response, type, resource, params));
};
