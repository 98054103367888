import React from 'react';
import {
  List,
  Datagrid,
  TextField,
  EditButton,
  ReferenceField,
  DateField,
  NumberField,
} from 'react-admin';

const LeaderboardList = props => (
  <List {...props} bulkActionButtons={false}>
    <Datagrid rowClick="edit">
      <TextField source="id" />
      <TextField source="visibility" />
      <TextField source="name" />
      <DateField source="updated_at" />
      <ReferenceField label="World" source="world.id" reference="worlds" allowEmpty>
        <TextField source="name" />
      </ReferenceField>
      <NumberField source="scoresCount" />
      <TextField source="metadata" />
      <EditButton />
    </Datagrid>
  </List>
);

export default LeaderboardList;
