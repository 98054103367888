import React from 'react';
import {
  List,
  Datagrid,
  TextField,
  EditButton,
  ReferenceField,
} from 'react-admin';

const FeaturedItemList = props => (
  <List {...props} bulkActionButtons={false}>
    <Datagrid rowClick="edit">
      <TextField source="order" sortable={false} />
      <ReferenceField label="Item" source="item.id" reference="workshopitems" allowEmpty>
        <TextField source="title" />
      </ReferenceField>
      <EditButton />
    </Datagrid>
  </List>
);

export default FeaturedItemList;
