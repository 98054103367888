import React from 'react';
import {
  Create,
  SimpleForm,
  TextInput,
} from 'react-admin';

const FeaturedItemCreate = props => (
  <Create {...props}>
    <SimpleForm>
      <TextInput source="item_id" />
    </SimpleForm>
  </Create>
);

export default FeaturedItemCreate;
