import React from 'react';
import {
  Edit,
  SimpleForm,
  ReferenceInput,
  SelectInput,
  TextInput,
} from 'react-admin';
import VisibilityInput from '../components/VisibilityInput';

const LeaderboardEdit = props => (
  <Edit {...props}>
    <SimpleForm>
      <ReferenceInput source="world.id" reference="worlds" allowEmpty>
        <SelectInput optionText="name" />
      </ReferenceInput>
      <TextInput source="name" />
      <VisibilityInput />
    </SimpleForm>
  </Edit>
);

export default LeaderboardEdit;
