import React from 'react';
import {
  List,
  Datagrid,
  TextField,
  ReferenceField,
  BulkDeleteButton,
  TextInput,
  Filter,
  DeleteButton,
  DateField,
} from 'react-admin';
import ReactCopyButtonWrapper from 'react-copy-to-clipboard';

const PostFilter = props => (
  <Filter {...props}>
    <TextInput label="Id" source="id" defaultValue="" alwaysOn />
  </Filter>
);

const PostBulkActionButtons = props => (
  <BulkDeleteButton {...props} />
);

const SavefileField = ({ record, source }) => {
  const value = record[source];
  if (value) {
    return (
      <ReactCopyButtonWrapper text={record[source]}>
        <button type="button">
          {`Copy: ${record[source]}`}
        </button>
      </ReactCopyButtonWrapper>
    );
  }
  return <p>None</p>;
};

const ScoreList = props => (
  <List {...props} bulkActionButtons={<PostBulkActionButtons />} filters={<PostFilter />}>
    <Datagrid rowClick="edit">
      <TextField source="id" />
      <TextField source="value" />
      <DateField source="updatedAt" showTime />
      <SavefileField source="savefile" />
      <ReferenceField label="User" source="owner.id" reference="users" allowEmpty>
        <TextField source="display_name" />
      </ReferenceField>
      <DeleteButton />
    </Datagrid>
  </List>
);

export default ScoreList;
