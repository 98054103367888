import React from 'react';
import {
  List,
  Datagrid,
  TextField,
  EditButton,
  ReferenceField,
} from 'react-admin';

const FeaturedCampaignList = props => (
  <List {...props} bulkActionButtons={false}>
    <Datagrid rowClick="edit">
      <TextField source="order" sortable={false} />
      <ReferenceField label="Campaign" source="campaign.id" reference="workshopcampaigns" allowEmpty>
        <TextField source="title" />
      </ReferenceField>
      <EditButton />
    </Datagrid>
  </List>
);

export default FeaturedCampaignList;
