import React from 'react';
import {
  Create,
  SimpleForm,
  TextInput,
} from 'react-admin';
import VisibilityInput from '../components/VisibilityInput';

const AdminUserCreate = props => (
  <Create {...props}>
    <SimpleForm>
      <TextInput source="email" />
      <TextInput source="password" />
    </SimpleForm>
  </Create>
);

export default AdminUserCreate;
