import React from 'react';
import {
  Edit,
  SimpleForm,
  TextInput,
} from 'react-admin';
import VisibilityInput from '../components/VisibilityInput';

const AchievementCreate = props => (
  <Edit {...props}>
    <SimpleForm>
      <TextInput source="name" />
      <VisibilityInput />
    </SimpleForm>
  </Edit>
);

export default AchievementCreate;
