import React from 'react';
import {
  List,
  Datagrid,
  TextField,
  DeleteButton,
  ImageField,
  ReferenceField,
  BulkDeleteButton,
  Filter,
  TextInput,
} from 'react-admin';

const PostFilter = props => (
  <Filter {...props}>
    <TextInput label="Id" source="id" defaultValue="" alwaysOn />
  </Filter>
);

const PostBulkActionButtons = props => (
  <BulkDeleteButton {...props} />
);

const GalleryEntryList = props => (
  <List {...props} bulkActionButtons={<PostBulkActionButtons />} filters={<PostFilter />}>
    <Datagrid>
      <TextField source="id" />
      <ReferenceField label="Leaderboard" source="leaderboard.id" reference="leaderboards" allowEmpty linkType="show">
        <TextField source="name" />
      </ReferenceField>
      <TextField source="result" />
      <TextField source="maxStress" />
      <TextField source="budgetUsed" />
      <ReferenceField label="User" source="ownedBy.id" reference="users" allowEmpty linkType="show">
        <TextField source="display_name" />
      </ReferenceField>
      <ImageField source="videoPreview" />
      <DeleteButton />
    </Datagrid>
  </List>
);

export default GalleryEntryList;
