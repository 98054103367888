import React from 'react';
import {
  Admin,
  Resource,
  ListGuesser,
} from 'react-admin';
import PersonIcon from '@material-ui/icons/Person';
import VerifiedUserIcon from '@material-ui/icons/VerifiedUser';
import LanguageIcon from '@material-ui/icons/Language';
import GradeIcon from '@material-ui/icons/Grade';
import ScoreIcon from '@material-ui/icons/Score';
import FormatListNumberedIcon from '@material-ui/icons/FormatListNumbered';
import ExtensionIcon from '@material-ui/icons/Extension';
import TurnedInNotIcon from '@material-ui/icons/TurnedInNot';
import BookIcon from '@material-ui/icons/Book';
import PhotoLibraryIcon from '@material-ui/icons/PhotoLibrary';

import authProvider from './authProvider';
import dataProvider from './dataProvider';
import Dashboard from './views/Dashboard';
import LeaderboardList from './resources/LeaderboardList';
import LeaderboardCreate from './resources/LeaderboardCreate';
import LeaderboardEdit from './resources/LeaderboardEdit';
import AchievementList from './resources/AchievementList';
import AchievementCreate from './resources/AchievementCreate';
import AchievementEdit from './resources/AchievementEdit';
// Worlds
import WorldList from './resources/WorldList';
import WorldEdit from './resources/WorldEdit';
import WorldCreate from './resources/WorldCreate';
// Users
import UserList from './resources/UserList';
import UserEdit from './resources/UserEdit';

import AdminUserCreate from './resources/AdminUserCreate';
import AdminUserList from './resources/AdminUserList';
import ScoreList from './resources/ScoreList';
import FeaturedItemList from './resources/FeaturedItemList';
import FeaturedItemEdit from './resources/FeaturedItemEdit';
import WorkshopItemList from './resources/WorkshopItemList';
import FeaturedItemCreate from './resources/FeaturedItemCreate';
import WorkshopCampaignList from './resources/WorkshopCampaignList';
import FeaturedCampaignList from './resources/FeaturedCampaignList';
import FeaturedCampaignEdit from './resources/FeaturedCampaignEdit';
import FeaturedCampaignCreate from './resources/FeaturedICampaignCreate';
import WeeklyChallengeItemList from './resources/WeeklyChallengeItemList';
import WeeklyChallengeItemCreate from './resources/WeeklyChallengeItemCreate';
import WeeklyChallengeItemEdit from './resources/WeeklyChallengeItemEdit';
import GalleryEntryList from './resources/GalleryEntryList';

const App = () => (
  <Admin
    authProvider={authProvider}
    dataProvider={dataProvider}
    dashboard={Dashboard}
  >
    <Resource
      name="leaderboards"
      list={LeaderboardList}
      edit={LeaderboardEdit}
      create={LeaderboardCreate}
      icon={FormatListNumberedIcon}
    />
    <Resource
      name="achievements"
      list={AchievementList}
      edit={AchievementEdit}
      create={AchievementCreate}
      icon={GradeIcon}
    />
    <Resource
      name="worlds"
      list={WorldList}
      edit={WorldEdit}
      create={WorldCreate}
      icon={LanguageIcon}
    />
    <Resource
      name="weeklychallengeitems"
      options={{ label: 'Weekly Challenges' }}
      list={WeeklyChallengeItemList}
      edit={false}
      create={WeeklyChallengeItemCreate}
      icon={ExtensionIcon}
    />
    <Resource
      name="galleryentries"
      options={{ label: 'Gallery' }}
      list={GalleryEntryList}
      edit={false}
      create={false}
      icon={PhotoLibraryIcon}
    />
    <Resource
      name="workshopitems"
      options={{ label: 'WS Items' }}
      list={WorkshopItemList}
      edit={false}
      create={false}
      icon={ExtensionIcon}
    />
    <Resource
      name="workshopcampaigns"
      options={{ label: 'WS Campaigns' }}
      list={WorkshopCampaignList}
      edit={false}
      create={false}
      icon={ExtensionIcon}
    />
    <Resource
      name="featureditems"
      options={{ label: 'Featured Items' }}
      list={FeaturedItemList}
      edit={FeaturedItemEdit}
      create={FeaturedItemCreate}
      icon={TurnedInNotIcon}
    />
    <Resource
      name="featuredcampaigns"
      options={{ label: 'Featured Campaigns' }}
      list={FeaturedCampaignList}
      edit={FeaturedCampaignEdit}
      create={FeaturedCampaignCreate}
      icon={TurnedInNotIcon}
    />
    <Resource
      name="users"
      list={UserList}
      edit={UserEdit}
      icon={PersonIcon}
    />
    <Resource
      name="scores"
      list={ScoreList}
      icon={ScoreIcon}
    />
    <Resource
      options={{ label: 'Admins' }}
      name="adminusers"
      list={AdminUserList}
      create={AdminUserCreate}
      icon={VerifiedUserIcon}
    />
  </Admin>
);

export default App;
