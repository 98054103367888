import React from 'react';
import {
  Create,
  SimpleForm,
  TextInput,
  ImageInput,
  FileInput,
  FileField,
  NumberInput,
  ImageField,
} from 'react-admin';

const WeeklyChallengeItemCreate = props => (
  <Create {...props}>
    <SimpleForm>
      <TextInput source="title" />
      <TextInput source="description" />
      <ImageInput source="preview_payload" accept="image/png">
        <ImageField source="src" title="title" />
      </ImageInput>
      <FileInput source="payload">
        <FileField source="src" title="title" />
      </FileInput>
      <NumberInput source="week" />
    </SimpleForm>
  </Create>
);

export default WeeklyChallengeItemCreate;
