import React from 'react';
import {
  Create,
  SimpleForm,
  TextInput,
} from 'react-admin';
import VisibilityInput from '../components/VisibilityInput';

const AchievementCreate = props => (
  <Create {...props}>
    <SimpleForm>
      <TextInput source="name" />
      <VisibilityInput />
    </SimpleForm>
  </Create>
);

export default AchievementCreate;
