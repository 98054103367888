import React from 'react';
import {
  List,
  Datagrid,
  TextField,
  DeleteButton,
  ImageField,
  UrlField,
} from 'react-admin';

const WeeklyChallengeItemList = props => (
  <List {...props} bulkActionButtons={false}>
    <Datagrid rowClick="edit">
      <TextField source="id" />
      <TextField source="title" />
      <ImageField source="preview" />
      <TextField source="payload" />
      <DeleteButton />
    </Datagrid>
  </List>
);

export default WeeklyChallengeItemList;
