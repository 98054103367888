import React from 'react';
import {
  List,
  Datagrid,
  TextField,
  EditButton,
  NumberField,
  DeleteButton,
  Filter,
  TextInput,
} from 'react-admin';

const PostFilter = props => (
  <Filter {...props}>
    <TextInput label="Id" source="id" defaultValue="" alwaysOn />
    <TextInput label="Display name" source="display_name" defaultValue="" alwaysOn />
  </Filter>
);

const UserList = props => (
  <List {...props} filters={<PostFilter />}>
    <Datagrid rowClick="edit">
      <TextField source="id" />
      <TextField label="platform" source="platform" />
      <TextField label="Display name" source="display_name" />
      <NumberField label="Steam id" source="steamId" />
      <NumberField label="EGS id" source="egsId" />
      <NumberField source="followers" />
      <NumberField source="scoresCount" />
      <NumberField source="is_banned" />
      <DeleteButton label="Ban" />
    </Datagrid>
  </List>
);

export default UserList;
