import React from 'react';
import {
  List,
  Datagrid,
  TextField,
} from 'react-admin';

const AdminUserList = props => (
  <List {...props} bulkActionButtons={false}>
    <Datagrid rowClick="edit">
      <TextField source="email" />
    </Datagrid>
  </List>
);

export default AdminUserList;
