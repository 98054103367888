import React from 'react';
import {
  SelectInput,
} from 'react-admin';

const VisibilityInput = () => (
  <SelectInput
    source="visibility"
    choices={[
      { id: 'public', name: 'public' },
      { id: 'hidden', name: 'hidden' },
    ]}
  />
);

export default VisibilityInput;
